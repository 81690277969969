import React, { useState, useEffect } from 'react'
import { object, array } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import Select from 'react-select'

import FilterRow from './FilterRow'
import { DROPDOWN_STYLES } from '../util/constants'
// import MediaLibraryItem from './ContentBlocks/MediaLibraryItem'
import * as Styled from './styles/ProductDirectory.styles'
import { RegionContainer } from './styles/Utility.styles'
import ProductItem from '../components/ContentBlocks/ProductItem'
import Disclaimer from '../components/ContentBlocks/Disclaimer'
import CtaLink from '../components/ContentBlocks/CtaLink'
import { TopLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import PaginationControl from '../components/PaginatonControl'
import {
  getStorageString,
  setStorageString,
  removeStorageString,
  StorageKey,
  StorageType,
} from '../util/storage'
import WithLocation from '../util/WithLocation'
import SEAL_HERO from '../images/seal-of-recognition.png'

const ITEMS_PER_PAGE = 18

const sortOptions = [
  { value: 'Newest->Oldest', label: 'Newest\u2192Oldest' },
  { value: 'Oldest->Newest', label: 'Oldest\u2192Newest' },
  { value: 'A->Z', label: 'A\u2192Z' },
  { value: 'Z->A', label: 'Z\u2192A' },
]

const getProductTypeOptions = types => {
  const options = []
  types.map(type => {
    options.push({
      value: type.node.data.product_type,
      label: type.node.data.product_type,
    })
  })
  return options
}

const getProductBrandOptions = companies => {
  const options = []
  companies.map(company => {
    options.push({
      value: company.node.data.product_company,
      label: company.node.data.product_company,
    })
  })
  return options
}

const getProductCategoryOptions = categories => {
  const options = []
  categories.map(category => {
    options.push({
      value: category.node.data.product_category,
      label: category.node.data.product_category,
    })
  })
  return options
}

const ProductItemGrid = ({
  sortOption,
  brandFilter,
  typeFilter,
  categoryFilter,
  productsList,
  firstItemForPage,
  lastItemForPage,
  setFilteredItemsCount,
}) => {
  const filtered = productsList
    .filter(item => {
      if (!categoryFilter) {
        return true
      }
      if (!item?.node?.data?.product_category) {
        return false
      }
      return categoryFilter
        ? item?.node?.data?.product_category.document.data.product_category ===
            categoryFilter
        : true
    })
    .filter(item => {
      if (!brandFilter) {
        return true
      }
      if (!item?.node?.data.product_company) {
        return false
      }
      return brandFilter
        ? item?.node?.data.product_company.document.data.product_company ===
            brandFilter
        : true
    })
    .filter(item => {
      if (!typeFilter) {
        return true
      }
      if (!item?.node?.data.product_type) {
        return false
      }
      return typeFilter
        ? item?.node?.data.product_type.document.data.product_type ===
            typeFilter
        : true
    })
  const sorted = filtered.sort((a, b) => {
    const aDate = new Date(a.node.first_publication_date)
    const bDate = new Date(b.node.first_publication_date)
    const aName = a.node.data.product_name?.text.toLowerCase() ?? ''
    const bName = b.node.data.product_name?.text.toLowerCase() ?? ''
    if (sortOption.value === 'Newest->Oldest') {
      return aDate > bDate ? -1 : 1
    } else if (sortOption.value === 'Oldest->Newest') {
      return aDate < bDate ? -1 : 1
    } else if (sortOption.value === 'A->Z') {
      return aName < bName ? -1 : 1
    } else if (sortOption.value === 'Z->A') {
      return aName > bName ? -1 : 1
    } else {
      return 0
    }
  })

  useEffect(() => {
    setFilteredItemsCount(filtered.length)
  }, [filtered])

  return (
    <Styled.ItemsGridContainer>
      {sorted.slice(firstItemForPage, lastItemForPage).map(item => (
        <ProductItem key={item.node.id} item={item.node.data} />
      ))}
    </Styled.ItemsGridContainer>
  )
}

const ProductDirectory = ({
  doc,
  types,
  categories,
  companies,
  productsList,
  topLineAdBlockId,
  paginationChangeAction,
}) => {
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [brandFilter, setBrandFilter] = useState(null)
  const [typeFilter, setTypeFilter] = useState(null)

  const [sortOption, setSortOption] = useState(sortOptions[0])
  const [firstItemForPage, setFirstItemForPage] = useState(0)
  const [lastItemForPage, setLastItemForPage] = useState(ITEMS_PER_PAGE)
  const [filteredItemsCount, setFilteredItemsCount] = useState(null)

  const hasPreviousCategoryFilter = getStorageString({
    key: StorageKey.PRODUCT_CATEGORY_FILTER,
    storageType: StorageType.SESSION,
  })
  const hasPreviousBrandFilter = getStorageString({
    key: StorageKey.PRODUCT_BRAND_FILTER,
    storageType: StorageType.SESSION,
  })
  const hasPreviousTypeFilter = getStorageString({
    key: StorageKey.PRODUCT_TYPE_FILTER,
    storageType: StorageType.SESSION,
  })
  useEffect(() => {
    if (hasPreviousCategoryFilter) {
      setCategoryFilter(hasPreviousCategoryFilter)
    }
    if (hasPreviousBrandFilter) {
      setBrandFilter(hasPreviousBrandFilter)
    }
    if (hasPreviousTypeFilter) {
      setTypeFilter(hasPreviousTypeFilter)
    }
  }, [getStorageString])

  const setPref = (whichFilter, hasExistingFilter, whichKey) => {
    if (!whichFilter) {
      if (hasExistingFilter) {
        removeStorageString({
          key: whichKey,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: whichKey,
      value: whichFilter,
      storageType: StorageType.SESSION,
    })
  }

  useEffect(() => {
    setPref(
      categoryFilter,
      hasPreviousCategoryFilter,
      StorageKey.PRODUCT_CATEGORY_FILTER
    )
  }, [categoryFilter])

  useEffect(() => {
    setPref(
      brandFilter,
      hasPreviousBrandFilter,
      StorageKey.PRODUCT_BRAND_FILTER
    )
  }, [brandFilter])

  useEffect(() => {
    setPref(typeFilter, hasPreviousTypeFilter, StorageKey.PRODUCT_TYPE_FILTER)
  }, [typeFilter])

  if (!doc || !types || !categories || !companies) {
    return null
  }
  const pageTitle = doc.data.title?.text ?? ''
  const bodyText = doc.data?.body_text
  const disclaimer = doc.data?.product_page_disclaimer
  // const mediaOptions = doc.data?.
  return (
    <>
      <RegionContainer>
        <Styled.HeroRegion>
          <Styled.TextColumn>
            <Styled.HeroTitle>{pageTitle}</Styled.HeroTitle>
            <Styled.HeroBody as="div">
              {bodyText && <PrismicRichText field={bodyText.richText} />}
              {doc.data?.cta_link && (
                <CtaLink
                  text={doc.data.cta_link_text}
                  link={doc.data.cta_link}
                />
              )}
            </Styled.HeroBody>
          </Styled.TextColumn>
          <Styled.ImageColumn>
            <Styled.HeroImage src={SEAL_HERO} alt="Seal of Recognition" />
          </Styled.ImageColumn>
        </Styled.HeroRegion>
      </RegionContainer>
      {topLineAdBlockId && <TopLineGoogleAd id={topLineAdBlockId} />}
      <RegionContainer>
        <Styled.ProductDirectorySectionTitle>
          Product Directory
        </Styled.ProductDirectorySectionTitle>
      </RegionContainer>
      <FilterRow sectionTitle="Filter Products">
        <Styled.FilterLabelSelectPair>
          <Styled.FilterLabel>Category</Styled.FilterLabel>
          <Select
            placeholder="Category"
            options={getProductCategoryOptions(categories)}
            isClearable={true}
            styles={DROPDOWN_STYLES}
            width="160px"
            marginRight="10px"
            value={
              categoryFilter
                ? { value: categoryFilter, label: categoryFilter }
                : null
            }
            onChange={e =>
              e?.value ? setCategoryFilter(e.value) : setCategoryFilter(null)
            }
          />
        </Styled.FilterLabelSelectPair>
        <Styled.FilterLabelSelectPair>
          <Styled.FilterLabel>Brand</Styled.FilterLabel>
          <Select
            placeholder="Brand"
            options={getProductBrandOptions(companies)}
            isClearable={true}
            styles={DROPDOWN_STYLES}
            width="160px"
            marginRight="10px"
            value={
              brandFilter ? { value: brandFilter, label: brandFilter } : null
            }
            onChange={e =>
              e?.value ? setBrandFilter(e.value) : setBrandFilter(null)
            }
          />
        </Styled.FilterLabelSelectPair>
        <Styled.FilterLabelSelectPair>
          <Styled.FilterLabel>Type</Styled.FilterLabel>
          <Select
            placeholder="Type"
            options={getProductTypeOptions(types)}
            isClearable={true}
            styles={DROPDOWN_STYLES}
            width="160px"
            marginRight="10px"
            value={typeFilter ? { value: typeFilter, label: typeFilter } : null}
            onChange={e =>
              e?.value ? setTypeFilter(e.value) : setTypeFilter(null)
            }
          />
        </Styled.FilterLabelSelectPair>
        <Styled.FilterLabelSelectPair>
          <Styled.FilterLabel>Sort</Styled.FilterLabel>
          <Select
            options={sortOptions}
            value={sortOption}
            defaultValue={sortOptions[0]}
            styles={DROPDOWN_STYLES}
            width="180px"
            marginRight="10px"
            onChange={e => setSortOption({ value: e.value, label: e.label })}
          />
        </Styled.FilterLabelSelectPair>
      </FilterRow>

      <Styled.GreyFill>
        <RegionContainer>
          <ProductItemGrid
            sortOption={sortOption}
            brandFilter={brandFilter}
            typeFilter={typeFilter}
            categoryFilter={categoryFilter}
            productsList={productsList}
            firstItemForPage={firstItemForPage}
            lastItemForPage={lastItemForPage}
            setFilteredItemsCount={setFilteredItemsCount}
          />
          <PaginationControl
            itemCount={
              filteredItemsCount ? filteredItemsCount : productsList.length
            }
            itemsPerPage={ITEMS_PER_PAGE}
            setFirstItemForPage={setFirstItemForPage}
            setLastItemForPage={setLastItemForPage}
            paginationChangeAction={paginationChangeAction}
          />
        </RegionContainer>
        {disclaimer && <Disclaimer disclaimer={disclaimer} />}
      </Styled.GreyFill>
    </>
  )
}

ProductDirectory.propTypes = {
  doc: object,
  types: array,
  categories: array,
  companies: array,
  productsList: array,
}

export default WithLocation(ProductDirectory)

import React from 'react'
import { object } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

import CtaLink from './CtaLink'
import * as Styled from './styles/ProductItem.styles'

const ProductItem = ({ item }) => (
  <Styled.ProductRegion>
    <Styled.ProductImageContainer>
      <Styled.ProductImage
        src={item.product_image.url}
        alt={item.product_image.alt}
      />
    </Styled.ProductImageContainer>
    <Styled.ProductBrand>
      {item.product_company.document.data.product_company}
    </Styled.ProductBrand>
    <Styled.ProductName>
      <PrismicRichText field={item.product_name.richText} />
    </Styled.ProductName>
    <Styled.ProductDescription>
      <PrismicRichText field={item.product_description.richText} />
    </Styled.ProductDescription>
    <CtaLink
      text="Learn More About This Product"
      link={item.product_link}
      additionalStyles={Styled.additionalCtaStyles}
    />
  </Styled.ProductRegion>
)

ProductItem.propTypes = {
  item: object,
}

export default ProductItem

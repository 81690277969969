import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import SEARCH_ICON from '../../images/search-icon.svg'

export const FilterTitle = styled.h3`
  grid-column: 2 / 3;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--content-background-dark-blue);
  padding-top: 15px;
  padding-bottom: 7px;
  font-size: 1.4rem;
  line-height: 1.7;

  @media ${DEVICE.tabletL} {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`

export const FilterDropdownRow = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 16px;
  }

  @media ${DEVICE.laptopS} {
    flex-direction: row;

    > div {
      margin-bottom: 0;
    }
  }
`

export const SearchField = styled.input`
  width: 100%;
  background: url(${SEARCH_ICON}) center right 16px no-repeat;
  padding-left: 16px;
  background-color: var(--content-background-light);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  height: 42px;
  padding-right: 48px;

  ::placeholder {
    color: var(--content-background-dark-blue);
  }
`

export const MediaLibrarySearchField = styled(SearchField)`
  width: 240px;
`
